import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Піцерія Дивовижна Італія
			</title>
			<meta name={"description"} content={"Скуштуйте найкращі страви італійської кухні"} />
			<meta property={"og:title"} content={"Піцерія Дивовижна Італія"} />
			<meta property={"og:description"} content={"Скуштуйте найкращі страви італійської кухні"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/nero00007-83425ddc.png?v=2024-07-18T11:35:43.635Z"} />
		</Helmet>
		<Components.Header12 />
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="--headline2"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Відкрийте для себе наші кулінарні шедеври
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="--lead"
				color="--darkL1"
				padding="0px 280px 0px 280px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				Наше меню - це свято італійських кулінарних традицій, в якому представлені різноманітні страви, приготовані з пристрастю та турботою.{" "}
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="0px 0px 30px 0px"
				grid-template-columns="repeat(2, 1fr)"
				md-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/6698f9e3995d120018f20fe0/images/360_F_726207644_xbv1wEfKuzido4LAdxGm0JW06JdFSn4L.jpg?v=2024-07-18T11:35:43.655Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/360_F_726207644_xbv1wEfKuzido4LAdxGm0JW06JdFSn4L.jpg?v=2024-07-18T11%3A35%3A43.655Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/360_F_726207644_xbv1wEfKuzido4LAdxGm0JW06JdFSn4L.jpg?v=2024-07-18T11%3A35%3A43.655Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/360_F_726207644_xbv1wEfKuzido4LAdxGm0JW06JdFSn4L.jpg?v=2024-07-18T11%3A35%3A43.655Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/360_F_726207644_xbv1wEfKuzido4LAdxGm0JW06JdFSn4L.jpg?v=2024-07-18T11%3A35%3A43.655Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/360_F_726207644_xbv1wEfKuzido4LAdxGm0JW06JdFSn4L.jpg?v=2024-07-18T11%3A35%3A43.655Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/360_F_726207644_xbv1wEfKuzido4LAdxGm0JW06JdFSn4L.jpg?v=2024-07-18T11%3A35%3A43.655Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698f9e3995d120018f20fe0/images/360_F_726207644_xbv1wEfKuzido4LAdxGm0JW06JdFSn4L.jpg?v=2024-07-18T11%3A35%3A43.655Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="rgba(255, 222, 85, 0.65)"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Класичні піци
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Маргарита: Свіжі помідори, моцарела та базилік.
Пепероні: Пікантна пепероні з моцарелою та томатним соусом.
Вегетаріанський: Мікс свіжих овочів з моцарелою та томатним соусом.
Фірмові піци
La Dolce Special: Прошутто, рукола та нарізаний пармезан.
Солодощі з морепродуктами: Креветки, кальмари та свіжа зелень.
Чотири сири: Асорті з моцарели, пармезану, горгонзоли та козячого сиру.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="70%"
					display="flex"
					align-items="center"
					flex-direction="column"
					justify-content="center"
					padding="0px 80px 0px 80px"
					lg-width="65%"
					md-width="100%"
					md-margin="0px 0px 15px 0px"
					md-padding="0px 0 0px 0"
					lg-padding="0px 40px 0px 40px"
				>
					<Image
						src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/tray.png?v=2023-04-28T11:18:00.724Z"
						display="block"
						width="80px"
						height="80px"
						margin="0px 0px 25px 0px"
						srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tray.png?v=2023-04-28T11%3A18%3A00.724Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tray.png?v=2023-04-28T11%3A18%3A00.724Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tray.png?v=2023-04-28T11%3A18%3A00.724Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tray.png?v=2023-04-28T11%3A18%3A00.724Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tray.png?v=2023-04-28T11%3A18%3A00.724Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tray.png?v=2023-04-28T11%3A18%3A00.724Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tray.png?v=2023-04-28T11%3A18%3A00.724Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 22px/1.5 --fontFamily-serifGaramond" text-align="center">
						У Піцерія Дивовижна Італія ми пропонуємо більше, ніж просто піцу. Наше меню - це подорож багатим кулінарним ландшафтом Італії, де представлені різноманітні страви, які підкреслюють смаки та традиції італійської кухні. Кожна страва в нашому меню готується з найсвіжіших інгредієнтів і з максимальною увагою до деталей, що гарантує, що ви отримаєте незабутні враження від вечері, які будуть приємними та незабутніми.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://images.unsplash.com/photo-1709429790175-b02bb1b19207?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://images.unsplash.com/photo-1709429790175-b02bb1b19207?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1709429790175-b02bb1b19207?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1709429790175-b02bb1b19207?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1709429790175-b02bb1b19207?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1709429790175-b02bb1b19207?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1709429790175-b02bb1b19207?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1709429790175-b02bb1b19207?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						background="rgba(255, 222, 85, 0.65)"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Класичні макаронні вироби
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Спагеті карбонара: Панчетта, яйця та пармезан.{"\n"}
							<br />
							Пенне Арраббіата: Пікантний томатний соус з часником та перцем чилі.{"\n"}
							<br />
							Лазанья: Шари пасти, м'ясний соус та вершковий бешамель.
Фірмові пасти{"\n"}
							<br />
							Песто Дженовезе: Песто зі свіжого базиліка з кедровими горішками та пармезаном.
Лінгвіне з морепродуктами: Мідії, молюски та креветки в соусі з білого вина.{"\n"}
							<br />
							Грибне різотто: Рис арборіо з асорті з лісових грибів.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://images.unsplash.com/photo-1536739782508-c2388552aad3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://images.unsplash.com/photo-1536739782508-c2388552aad3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1536739782508-c2388552aad3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1536739782508-c2388552aad3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1536739782508-c2388552aad3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1536739782508-c2388552aad3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1536739782508-c2388552aad3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1536739782508-c2388552aad3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
						background="rgba(255, 222, 85, 0.65)"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Антипасти
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Брускетта: Свіжі помідори, базилік та часник на підсмаженому хлібі.{"\n"}
							<br />
							Салат «Капрезе»: Шматочки моцарели, помідори та базилік, политі бальзамічною глазур'ю.{"\n"}
							<br />
							Часниковий хліб: Теплий хліб з часниковим маслом і зеленню.{"\n"}
							<br />
							Маленькі тарілки
Кальмари: Злегка запаніровані та обсмажені, подаються з соусом маринара.
Фаршировані гриби: Гриби, фаршировані пікантною сумішшю сирів і зелені.
Аранчіні: Хрусткі рисові кульки, фаршировані моцарелою та подаються з соусом маринара.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://images.unsplash.com/photo-1712263151181-6e20e559236f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://images.unsplash.com/photo-1712263151181-6e20e559236f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1712263151181-6e20e559236f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1712263151181-6e20e559236f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1712263151181-6e20e559236f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1712263151181-6e20e559236f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1712263151181-6e20e559236f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1712263151181-6e20e559236f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
						background="rgba(255, 222, 85, 0.65)"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Десерти
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Тірамісу: Шари просочених кавою бісквітів та крем з маскарпоне.{"\n"}
							<br />
							Каннолі: Хрусткі оболонки з тіста, наповнені солодкою рікоттою та шоколадною крихтою.{"\n"}
							<br />
							Панна Котта: Шовковисто-гладкий заварний крем зі свіжими ягодами.{"\n"}
							<br />
							Фірмові десерти
Аффогато: Ванільне морозиво «потонуло» у порції гарячого еспресо.
Шоколадний торт «Лава»: Тепле шоколадне тістечко з розплавленою серединою.
Тріо джелато: Три кульки ремісничого італійського морозива.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer23 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});